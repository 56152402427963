<h3 class="ui-portal-title">Edit profile</h3>

<div class="ui-portal-columns">
  <div class="form frm-2-cols">
    <!-- Name -->
    <div class="control">
      <label>Name</label>
      <input [(ngModel)]="model.name" type="text" placeholder="Name">
    </div>

    <!-- Email -->
    <div class="control">
      <label>Email</label>
      <input [(ngModel)]="model.email" type="email" placeholder="Email" disabled>
    </div>
  </div>

  <!-- Logo -->
  <div class="control ctrl-logo">
    <label>Logo<span class="ui-icon-info" ui-tooltip=""></span></label>
    <ui-upload-input
      #ava
      uploadFieldName="file"
      [img]="avatar"
      (delete)="removeAvatar()"
      (change)="uploadFile(ava, $event, 'logo')"
    ></ui-upload-input>
  </div>
</div>

<div>
  <button class="btn-primary" (click)="onSaveClick()">Save profile</button>
</div>
